<template>
  <div id="app" style="background: #f4f6f8; height: auto; min-height: 100vh; padding-bottom: 8px;">
    <div v-if="data && data.code && data.code !== ''">
      <div style="border-radius: 0 0 10px 10px;">
        <div class="text">
          <div style="color: #FFF;">{{ '您查询的溯源码' }}</div>
          <div style="color: #FFF; font-size: 18px;">{{ data.code }}</div>
        </div>
        <div class="info">
          <div>
            <van-swipe :autoplay="3000" indicator-color="#34AC40" >
              <van-swipe-item v-for="(image, index) in images" :key="index">
                <img v-lazy="image" style="width: 100%; height: calc((100vw - 24px) * 0.64); object-fit: fill;background: #fff;" />
              </van-swipe-item>
            </van-swipe>
            <div class="info_top">
              <div style="border: 1px #F1F1F1 dashed; padding: 8px; border-radius: 6px;">
                <div style="font-size: 16px; color: #222222;">{{ data.productName }}</div>
                <div style="display: flex; margin-top: 4px;">
                  <div style="flex: 1;">
                    <div style="display: flex; color: #A1A1A1;">
                      商品规格:
                      <div style="color: #222222; margin-left: 8px;">{{ data.productSpec }}</div>
                    </div>
<!--                    <div style="display: flex; color: #A1A1A1; margin-top: 4px;">-->
<!--                      单-->
<!--                      <div style="width: 28px;" />-->
<!--                      位:-->
<!--                      <div style="color: #222222; margin-left: 8px;">{{ data.productUnit }}</div>-->
<!--                    </div>-->
                    <div style="display: flex; color: #A1A1A1; margin-top: 4px;">
                      保
                      <div style="width: 7px;" />
                      质
                      <div style="width: 7px;" />
                      期:
                      <div style="color: #222222; margin-left: 8px;">
                        {{ data.shelfLife > 0 ? (data.shelfLife + '天') : '待商家上传' }}
                      </div>
                    </div>
                    <div style="display: flex; color: #A1A1A1; margin-top: 4px;">
                      贮存条件:
                      <div style="color: #222222; margin-left: 8px;">
                        {{ data.traceabilityStorageCondition === 'Normal' ? '常温' : '冷链' }}
                      </div>
                    </div>

                  </div>
                  <van-image :src="data.thumbnailImageUrl" alt="" style="width: 80px; height: 80px;" />
                </div>
              </div>
            </div>

            <div class="threeTab">
              <ul class="clear">
                <li :class="[tabIndex === 0 ? 'now' : '']" @click="onClickChangeTab(0)">企业信息</li>
                <li :class="[tabIndex === 1 ? 'now' : '']" @click="onClickChangeTab(1)">从业人员</li>
                <li :class="[tabIndex === 2 ? 'now' : '']" @click="onClickChangeTab(2)">溯源链条</li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div class="tabContainer">
        <div v-if="tabIndex === 0">
          <CTBusinessInfoComponent ref="ctBusinessInfoComponent" :business="business"></CTBusinessInfoComponent>
        </div>
        <div v-if="tabIndex === 1">
          <CTPractitionerComponent ref="ctPractitionerInfoComponent" :data-list="practitionerDataList">
          </CTPractitionerComponent>
        </div>
        <div v-if="tabIndex === 2">
          <CTTraceComponent ref="ctTraceComponent" :data-list="traceDataList"></CTTraceComponent>
        </div>
      </div>
    </div>

    <div v-if="!(data && data.code && data.code !== '') && !firstLoad" style="padding-top: 8px;">
      <div
        style="background: #FFF; border-radius: 6px; padding: 8px 8px 8px 8px; margin: 0px 8px 8px 8px; height: calc(100vh - 24px)">
        <van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
          description="溯源码异常，请重新扫码进入" style="height: 100%" />
      </div>
    </div>

    <van-dialog v-model="show" confirm-button-text="关闭" @opened="onClickShowSteps">
      <div style="margin: 16px; font-weight: bold; text-align: center;">近七天步数图</div>
      <div id="container" style="height: 280px;"></div>
    </van-dialog>

    <van-dialog v-model="showFeedingRecord" confirm-button-text="关闭">
      <div style="margin: 16px; font-weight: bold; text-align: center;">饲养记录</div>
      <div style="height: 66vh; overflow-y: scroll; padding: 0 8px; font-size: 14px;">
        <van-list v-model="loading" :finished="true">
          <div style="display: flex; width: 100%;" v-for="(item, index) in feedingRecordList" :key="index">
            <div style="flex: 1; border: 1px #F1F1F1 dashed; padding: 8px; border-radius: 6px; margin: 4px 0;">
              <div style="display: flex; color: #A1A1A1;">
                饲养时间:
                <div style="flex: 1; color: #222222; margin-left: 8px;">
                  {{ getFeedingTime(item.feedingStartTime, item.feedingEndTime) }}
                </div>
              </div>
              <div style="display: flex; color: #A1A1A1; margin-top: 4px;">
                投喂饲料:
                <div style="flex: 1; color: #222222; margin-left: 8px;">{{ item.feedingFood }}</div>
              </div>
              <div style="display: flex; color: #A1A1A1; margin-top: 4px;">
                投喂频次:
                <div style="flex: 1; color: #222222; margin-left: 8px;">{{
                  getFeedingFrequency(item.feedingFrequency)
                }}
                </div>
              </div>
            </div>
          </div>

        </van-list>
      </div>
    </van-dialog>

    <van-dialog v-model="showVaccinumRecord" confirm-button-text="关闭">
      <div style="margin: 16px; font-weight: bold; text-align: center;">防疫记录</div>
      <div style="height: 66vh; overflow-y: scroll; padding: 0 8px; font-size: 14px;">
        <van-list v-model="loading" :finished="true">
          <div style="display: flex; width: 100%;" v-for="(item, index) in vaccinumRecordList" :key="index">
            <div style="flex: 1; border: 1px #F1F1F1 dashed; padding: 8px; border-radius: 6px; margin: 4px 0;">
              <div style="display: flex; color: #A1A1A1;">
                注射时间:
                <div style="flex: 1; color: #222222; margin-left: 8px;">{{ getTime(item.vaccineTime) }}</div>
              </div>
              <div style="display: flex; color: #A1A1A1; margin-top: 4px;">
                疫苗类型:
                <div style="flex: 1; color: #222222; margin-left: 8px;">{{ item.traceVaccineName }}</div>
              </div>
              <div style="display: flex; color: #A1A1A1; margin-top: 4px;">
                注射情况:
                <div style="flex: 1; color: #222222; margin-left: 8px;">{{ item.traceVaccineDosage }}</div>
              </div>
            </div>
          </div>

        </van-list>
      </div>
    </van-dialog>

    <van-dialog v-model="showBusiness" confirm-button-text="关闭">
      <div style="margin: 16px; font-weight: bold; text-align: center;">{{ data ? data.supplierName : '' }}</div>
      <div style="display: flex; color: #A1A1A1; margin-top: 4px;" v-if="data">
        <div style="display:flex; flex: 1; color: #222222; margin-left: 8px; align-items: center; place-content: center;">
          <div v-for="(item, index) in data.trustList" :key="index" style="display: flex;"
            :style="{ marginLeft: index !== 0 ? `10px` : `0` }">
            <img src="../assets/images/icon_trust.png" style="width: 18px; height: 18px;" />
            <div style="line-height: 18px;">{{ '认真' }}</div>
          </div>
        </div>
      </div>
      <!--      <div style="margin: 8px 16px 8px 16px; text-align: center;">-->
      <!--        {{ business.introduction }}-->
      <!--      </div>-->
      <div style="height: 55vh; overflow-y: scroll; padding: 0 8px; font-size: 14px;">
        <div style="color: #222222; font-weight: bold;">
          企业简介
          <van-image width="100%" height="22vh" :src="business ? business.image : ''" style="margin-top: 8px;" />
          <div style="color: #222222; font-size: 14px; margin-top: 8px; font-weight: normal;">{{
            business.introduction
          }}
          </div>
        </div>
        <div style="color: #222222; font-weight: bold; margin-top: 8px;">
          企业荣誉
          <div v-for="(item, index) in business.list" :key="index">
            <van-image width="100%" height="22vh" :src="item" style="margin-top: 8px;" />
          </div>

        </div>
      </div>
    </van-dialog>

    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{ '加载中' }}</van-loading>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import CTBusinessInfoComponent from "../components/CTBusinessInfoComponent";
import CTPractitionerComponent from "../components/CTPractitionerComponent";
import CTTraceComponent from "../components/CTTraceComponent";
// import { Chart } from '@antv/g2';
import {Toast,Image as VanImage ,Empty,Dialog,List,Loading,Swipe, SwipeItem} from "vant";
import { post } from "../utils/http";
import { getDiffDay } from "../utils/util";
import moment from "moment";
import { Lazyload } from 'vant';

Vue.use(Lazyload).use(Toast).use(VanImage).use(Empty).use(Dialog).use(List).use(Loading).use(Swipe).use(SwipeItem)

export default {
  name: "home",
  components: {
    CTBusinessInfoComponent,
    CTPractitionerComponent,
    CTTraceComponent
  },
  data() {
    return {
      firstLoad: true,
      traceCode: '',
      data: null,
      totalStepNumber: 0,

      sourceImage: '',
      sourceIntroduction: '',

      loadingAll: false,
      loading: false,
      tabIndex: 0,

      show: false,
      chart: null,//图表对象
      showData: [//图表中将要显示的数据

      ],

      showFeedingRecord: false,
      feedingRecordList: [],

      showVaccinumRecord: false,
      vaccinumRecordList: [],

      showBusiness: false,
      business: {
        title: '文昌鸡原产地简介',
        url: '',
        content: '安静的歌i加上见到过i哦啊手机导购i啊阿杰地阿佛第四届高is的架构as低结构i阿杰地宫颈癌搜地更煎熬is的警告是第几个',
        list: [{}, {}]
      },

      practitionerDataList: [],
      traceDataList: [],

      images: ['']

    }
  },

  mounted() {
    this.traceCode = this.$route.query.traceCode;
    if (this.traceCode && this.traceCode !== '') {
      this.requestData();
    }

  },

  methods: {
    onClickChangeTab(tabIndex) {
      if (tabIndex === this.tab) {
        return;
      }
      this.tabIndex = tabIndex;
    },
    onClickShow() {
      this.show = true;
    },

    //创建chart
    createChart() {
      if (!this.chart) {
        this.chart = new Chart({
          container: 'container',//chart容器id
          autoFit: false,//图表是否自适应容器宽高，默认为 false
          width: 320,//图标宽度
          height: 280,//图表高度
          padding: [40, 40, 40, 40],//图表内边距,依次为:上，右，下，左
          // defaultInteractions:"ellipsis-text",//配置图表默认交互，仅支持字符串形式。G2 默认支持的交互有 'tooltip', 'legend-filter', 'legend-active', 'continuous-filter', 'ellipsis-text'
          pixelRatio: window.devicePixelRatio,//设置设备像素比，默认取浏览器的值 window.devicePixelRatio
          renderer: "canvas",//指定渲染引擎，默认使用 canvas。可选：'canvas' | 'svg'
          visible: true,//chart 是否可见，默认为 true，设置为 false 则会隐藏。
        });
      }

      this.chart.source(this.showData);
      // 在x*y的坐标点上按z值绘制线条, 如果z值相同将使用直线连接
      this.chart.line().position('stepDate*当日步数').color('#34AC40');
      // 在x*y的坐标上按z值绘制圆点
      this.chart.point().position('stepDate*当日步数').size(4).color('#34AC40').shape('circle');
      this.chart.render();
    },

    requestData() {
      this.loadingAll = true;

      const url = '/api/public/v2/trace/scan/order/item/{tracingNo}/find'.replace('{tracingNo}', this.traceCode);
      post(url, {}, false)
        .then(res => {
          this.data = res.data;

          this.requestPractitioner();

          let images = []
          if (this.data.bannerImageUrl) {
            images = this.data.bannerImageUrl.split(';');
          }
          this.images = images;

          let honerImgUrls = [];
          // 营业执照
          if (this.data.businessLicenseImg) {
            honerImgUrls.push(this.data.businessLicenseImg);
          }
          // 食品经营许可证
          if (this.data.foodBusinessLicenseImg) {
            honerImgUrls.push(this.data.foodBusinessLicenseImg);
          }
          // 组织机构代码证
          if (this.data.orgCodeCertificateImg) {
            honerImgUrls.push(this.data.orgCodeCertificateImg);
          }
          // 食品生产许可证
          if (this.data.foodProductionLicenseImg) {
            honerImgUrls.push(this.data.foodProductionLicenseImg);
          }
          // 税务登记证
          if (this.data.taxRegistrationCertificateImg) {
            honerImgUrls.push(this.data.taxRegistrationCertificateImg);
          }
          // 食品溯源许可证
          if (this.data.foodCirculationLicenseImg) {
            honerImgUrls.push(this.data.foodCirculationLicenseImg);
          }
          // 食品安全等级
          if (this.data.foodSafetyLevelOfCateringServiceImg) {
            honerImgUrls.push(this.data.foodSafetyLevelOfCateringServiceImg);
          }
          // 安全体系认证
          if (this.data.foodSafetyManagementSystemCertificationImg) {
            honerImgUrls.push(this.data.foodSafetyManagementSystemCertificationImg);
          }

          let supplierDescriptionImages = []
          if (this.data.supplierDescriptionImageUrl) {
            supplierDescriptionImages = this.data.supplierDescriptionImageUrl.split(';');
          }
          this.business = {
            supplierName: this.data.supplierName,
            address: this.data.supplierAddress,
            supplierDescriptionImages: supplierDescriptionImages,
            supplierDescription: this.data.supplierDescription,
            honerImgUrls: honerImgUrls,

          }

          this.sourceImage = this.data.sourceImage;
          this.sourceIntroduction = this.data.sourceIntroduction;

          // 处理溯源链条
          let traceDataList = [];
          if(this.data.upstreamSupplierName){
            traceDataList.push({
            type: 'ADDRESS',
            upstreamSupplierName: this.data.upstreamSupplierName,
            purchaseTime: this.data.purchaseTime,
            quarantineCertificateUrl: this.data.quarantineCertificateUrl,
            qualityQualifiedUrl: this.data.qualityQualifiedUrl,
            legalInspectionUrl: this.data.legalInspectionUrl,
          });
          }
         
          traceDataList.push({
            type: 'SUPPLIER',
            supplierName: this.data.supplierName,
            sendTime: this.data.sendTime
          });
          traceDataList.push({
            type: 'DIGITAL_VOUCHER',
            digitalVoucherContractId: this.data.contractId,
            digitalVoucherReportTime: this.data.digitalVoucherReportTime
          });
        
          let qualityQualifiedUrls = [];
          if (this.data.qualityQualifiedUrl1) {
            qualityQualifiedUrls.push(this.data.qualityQualifiedUrl1)
          }
          if (this.data.qualityQualifiedUrl2) {
            qualityQualifiedUrls.push(this.data.qualityQualifiedUrl2)
          }
          if (this.data.qualityQualifiedUrl3) {
            qualityQualifiedUrls.push(this.data.qualityQualifiedUrl3)
          }
          if (this.data.qualityQualifiedUrl4) {
            qualityQualifiedUrls.push(this.data.qualityQualifiedUrl4)
          }
          if (this.data.qualityQualifiedUrl5) {
            qualityQualifiedUrls.push(this.data.qualityQualifiedUrl5)
          }
          if (qualityQualifiedUrls.length > 0 || this.data.certificateOfMeatQualityInspectionFileUrl || this.data.animalQuarantineCertificateFileUrl) {
            traceDataList.push({
              type: 'LICENSE',
              certificateOfMeatQualityInspectionFileUrl: this.data.certificateOfMeatQualityInspectionFileUrl, //肉品品质检验合格证证明
              animalQuarantineCertificateFileUrl: this.data.animalQuarantineCertificateFileUrl, //动物检疫合格证证明
              qualityQualifiedUrls: qualityQualifiedUrls,
            });
          }
          traceDataList.push({
            type: 'PURCHASER',
            purchaserName: this.data.purchaserName,
          });
          this.traceDataList = traceDataList;

        })
        .catch(err => {
          Toast(err.message);
        })
        .finally(() => {
          this.loadingAll = false;
          this.firstLoad = false;
        })
    },
    requestPractitioner() {
      this.loadingAll = true;
      const url = '/api/public/v2/trace/scan/practitioner/{businessId}/find'.replace('{businessId}', this.data.supplierId);
      post(url, {}, false)
        .then(res => {
          this.practitionerDataList = res.data;
        })
        .catch(err => {
          Toast(err.message);

        })
        .finally(() => {
          this.loadingAll = false;
        })
    },

    onClickShowSteps() {
      this.loadingAll = true;
      const url = '/api/public/v2/manufacture/tracing/{breedingBatchId}/findStepInfo'.replace('{breedingBatchId}', this.data.breedingBatchId);
      post(url, {}, false)
        .then(res => {
          this.show = true;

          this.showData = [];

          for (let i = 0; i < res.data.length; i++) {
            this.showData.push({
              stepDate: res.data[i].stepDate,
              当日步数: res.data[i].step
            })
          }

          this.createChart();

        })
        .catch(err => {
          Toast(err.message);
        })
        .finally(() => {
          this.loadingAll = false;
        })
    },

    getFeedingFrequency(feedingFrequency) {
      if (feedingFrequency) {
        switch (feedingFrequency) {
          case 'EVERYDAY':
            return '每天一次';
          case 'SINGLE_TIME':
            return '单次投喂';
          default:
            return ''
        }
      }
      return '';
    },
    getFeedingTime(startTime, endTime) {
      return moment(startTime).format('yyyy-MM-DD') + "至" + moment(endTime).format('yyyy-MM-DD')
    },
    getTime(startTime) {
      return moment(startTime).format('yyyy-MM-DD HH:mm:ss')
    }

  },
}
</script>

<style lang="less" scoped>
.text {
  padding: 16px 16px 22px;
  background: url('../assets/images/bg-color.png') no-repeat center / cover;
}

.info {
  background: #FFF;
  padding: 16px 8px 0 8px;
  margin-top: -10px;
  border-radius: 10px;

  .info_top {
    .van-image {
      border-radius: 6px;
      overflow: hidden;
    }
  }

}

.box {
  background: #fff;
  border-radius: 6px;
  padding: 8px;
  margin: 10px 0;

  &+.box {
    margin-top: 16px;
  }

  .title {
    padding-left: 25px;
    position: relative;
    margin-bottom: 8px;

    &::after,
    &::before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      background: #64b65b;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 5px;
      z-index: 2;
    }

    &::after {
      background: #cce8cb;
      z-index: 1;
      left: 4px;
      top: 1px;
    }
  }
}
.tabContainer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 0 8px 8px 8px;
  }
  


</style>

